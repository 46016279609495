<template>
  <div>
    <div class="p-4 mt-5 tableContainer">
      <table class="table">
        <tr class="loadingOverlay" v-if="loading">
          <b-spinner small></b-spinner>
        </tr>
        <thead>
          <tr class="headingRow">
            <th scope="col">#</th>
            <th scope="col">Naziv</th>
            <th scope="col">Stanje</th>
            <th scope="col">Kategorija</th>
          </tr>
        </thead>
        <tbody>
          <tr class="dataRow" v-for="(key, i) in equipment.data" :key="i.id">
            <td>{{ i + 1 }}</td>
            <td>{{ key.equipment_detail.name | truncate(40) }}</td>
            <td>{{ key.equipment_detail.state }}</td>
            <td>{{ key.equipment_detail.category.name }}</td>
          </tr>
          <tr v-if="!resultsCount && !loading">
            <td colspan="8" class="text-center">
              <div class="noData">
                <img src="@/assets/icons/cancel.svg" alt="" class="mr-3" />
                <p>{{ $t("no_data") }}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginationContainer mt-3" v-if="resultsCount && !loading">
      <div class="text">
        {{ equipment.meta.from }}-{{ equipment.meta.to }} od
        {{ equipment.meta.total }}
      </div>
      <pagination
        class="my-1"
        align="right"
        :data="equipment"
        :limit="1"
        @pagination-change-page="currentPage = $event"
        show-disabled
      >
        <span slot="prev-nav"><i class="fas fa-chevron-left"></i></span>
        <span slot="next-nav"><i class="fas fa-chevron-right"></i></span>
      </pagination>
    </div>
  </div>
</template>
<script>
import { API_URL } from "../../_plugins/axios";
import Pagination from "laravel-vue-pagination";

export default {
  name: "MyEquipment",
  components: {
    Pagination,
  },
  data() {
    return {
      equipment: [],
      currentPage: 1,
      loading: false,
    };
  },
  methods: {
    getAllEquipment() {
      this.loading = true;
      API_URL.get("my-equipment", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.equipment = response.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getAllEquipment();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Zadužena oprema",
    });
  },
  computed: {
    resultsCount() {
      return this.equipment?.data?.length;
    },
  },
};
</script>
<style src="./equipment.scss" lang="scss" scoped></style>
<style lang="scss">
.paginationContainer {
  .pagination-page-nav {
    display: none;
  }
  ul {
    li.page-item {
      a {
        border: none !important;
        background-color: transparent !important;
      }
    }
  }
}
.page-item.disabled .page-link {
  color: $pagination_disabled;
}
.page-item .page-link {
  color: $text_main;

  &:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>
